<template>
  <v-col class="xs12 sm4 pa-2 text-center">
    <div class="block block-contacts">

      <div class="mcard">
        <div class="circ">
          <img :src="photo" id="memberid"/>
        </div>

        <h1>{{ name }}</h1>
        <h6>{{ contactfunction }}</h6>
        <span>E: <a :href="'mailto:' + email">{{ email }}</a></span>
        <span>T: <a :href="'tel:' + phone">{{ phone }}</a></span>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'contact-block',
  data() {
    return {};
  },
  props: [
    'email', 'photo', 'id', 'name', 'phone', 'contactfunction',
  ],
};
</script>

<style scoped>

</style>
