<template>
  <v-row v-if="currentOrganisation" class="page page-contacts">
    <contact-block
        v-for="contact in contacts"
        :id="contact.id"
        :key="contact.id"
        :contactfunction="contact.function"
        :email="contact.email"
        :name="contact.name"
        :organisations="contact.organisations"
        :phone="contact.phone"
        :photo="contact.photo"
    />
  </v-row>
</template>

<script>

import ContactBlock from '../components/contacts/Block.vue';

export default {
  name: 'contacts',
  components: { ContactBlock },
  props: {
    currentOrganisation: {
      required: true,
    },
  },
  computed: {
    contacts() {
      return this.currentOrganisation.contacts.map((contact) => {
        return {
          email: contact.email,
          id: contact.id,
          name: contact.name,
          phone: contact.contact.phone,
          photo: contact?.profile_image?.public_path,
          function: this.typeIdToFunctionTitle(contact.organisation_contact_type_id),
        };
      });
    },
  },
  methods: {
    typeIdToFunctionTitle(typeId) {
      switch (typeId) {
        case 1:
          return 'Relatiebeheerder';
        case 2:
          return 'Customer Support Backoffice';
        case 3:
          return 'Customer Support Frontoffice';
        case 4:
          return 'Energieadviseur';
        case 5:
          return 'Energieanalist';
        case 6:
          return 'Accountmanager';
      }
    },
  },
};

</script>

<style scoped>

</style>
